.data-loading-indicator svg {
  height: 35px;
  width: 35px;
}

.general-statistics-row-layout .general-statistics-column-element {
  flex: 1;
}

.general-statistics-column-layout {
  width: 60%;
}

.general-statistics-column-layout .general-statistics-column-element {
  flex-basis: 30%;
  box-sizing: border-box;
  margin-top: 100px;
}
